<template>
  <v-container
    class="px-sm-10 py-sm-10"
    fluid
    style="min-height: 100vh; background: #e6e6e6"
  >
    <v-card class="px-2 mx-2" elevation="0">
      <v-data-table
        :headers="headers"
        :items="dataTable"
        :items-per-page="100"
        class="elevation-0"
        mobile-breakpoint="100"
        hide-default-footer
      >
        <template v-slot:top>
          <v-row class="mt-4 px-8 pt-4 pb-10">
            <v-col cols="12" sm="5">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Cari"
                color="#36AC87"
                single-line
                hide-details
                clearable
                outlined
                dense
                @keyup="filterData"
                @click:append="filterData"
                @click:clear="filterClear"
              />
            </v-col>
            <v-col class="text-right" cols="12" sm="7">
              <v-btn
                v-if="isAllowModify"
                color="#2E976C"
                class="text-capitalize"
                style="color: white"
                @click="toCreate"
              >
                Buat Artikel
              </v-btn>
            </v-col>
          </v-row>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <v-switch
            v-if="item.can_edit"
            v-model="item.status"
            color="#008EB7"
            :false-value="0"
            :true-value="1"
            @change="updateStatus(item.article_hash)"
          />
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div v-if="isAllowModify" class="d-flex">
            <div class="action-style mx-2" @click="toDetail(item)">
              <v-icon class="action-icon"> mdi-eye </v-icon>
            </div>

            <div
              class="action-style"
              @click="toEdit(item)"
              v-if="item.can_edit"
            >
              <v-icon class="action-icon" color="#FFC709"> mdi-pencil </v-icon>
            </div>

            <div
              class="action-style mx-2"
              @click="toDelete(item)"
              v-if="item.can_edit"
            >
              <v-icon class="action-icon" color="#EA3E3A"> mdi-delete </v-icon>
            </div>
          </div>
        </template>

        <template v-slot:[`item.slider`]="{ item }">
          <v-checkbox v-if="isAllowModify" :input-value="item.show_web" @change="checkboxShowWeb(item)"></v-checkbox>{{ item.name }}
        </template>

        <template v-slot:[`item.name`]="{ item }">
          <span style="color: #2b7dec; cursor: pointer" @click="toDetail(item)">
            {{ item.name }}
          </span>
        </template>

        <template v-slot:footer>
          <div class="text-center my-5 py-3">
            <v-pagination
              v-model="page"
              :length="pageCount"
              color="#2E976C"
              :total-visible="5"
              @input="onChangePage"
            />
            <span style="font-size: 12px; font-style: italic">
              {{ itemCount + " dari " + itemTotal + " ditampilkan" }}
            </span>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <modal-konfirmasi :show="modal" @submit="confirm" />
  </v-container>
</template>

<script>
import axios from "axios";
import ModalKonfirmasi from "../komponen/modal/ModalKonfirmasi.vue";
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.defaults.headers.post["x-api-key"] = localStorage.getItem("token");

export default {
  components: {
    ModalKonfirmasi,
  },

  data: () => ({
    modal: false,
    dialog: false,
    dataDetail: null,
    search: "",
    headers: [
      { text: "Entitas", align: "start", value: "entity", sortable: false },
      {
        text: "Judul Artikel",
        align: "start",
        value: "title",
        sortable: false,
      },
      { text: "Tanggal Buat", value: "date", sortable: false },
      { text: "Dibuat Oleh", value: "created_by", sortable: false },
      { text: "Status", value: "status", sortable: false },
      { text: "Muncul di Slider", value: "slider", sortable: false },
      { text: "", value: "actions", sortable: false },
    ],
    dataTable: [],
    page: 1,
    pageCount: 0,
    itemCount: 0,
    itemTotal: 0,
    selectedItem: null,
    isAllowModify: false,
  }),

  computed: {},

  watch: {},

  created() {
    this.initialize(this.page);
    const userInfo = JSON.parse(localStorage.getItem("userinfo"));
    const userMenus = userInfo.menu_function;
    if (userMenus.includes("ARTICLE_MOD")) this.isAllowModify = true;
  },

  methods: {
    closeDialog() {
      this.dialog = false;
    },

    toCreate() {
      this.$router.push({ name: "ArtikelTambah" });
    },

    toEdit(p) {
      this.$router.push({
        name: "ArtikelEdit",
        params: { id: p.article_hash },
      });
    },

    toDetail(p) {
      // console.log(p)
      this.$router.push({
        name: "ArtikelDetail",
        params: { id: p.article_hash },
      });
    },

    toDelete(p) {
      // console.log(p)
      this.selectedItem = p;
      this.modal = true;
    },

    onChangePage(e) {
      this.page = e;
      this.initialize(e, this.search);
    },

    filterData() {
      this.initialize(this.page, this.search);
    },

    filterClear() {
      this.initialize(this.page);
    },

    confirm(p) {
      // console.log(p)
      if (p === 0) {
        this.modal = false;
      } else {
        this.modal = false;
        this.submit();
      }
    },

    initialize(page, search) {
      // Check user menus
      // const userMenus = localStorage.getItem('userMenus').split(',')

      // if (userMenus.includes('CUSTOMER_VERIFICATION')) {
      //   this.isAllowVerification = true
      // }

      const requestBody = {
        // type: type,
        page: page,
        search: search,
      };

      axios
        .post("/v1/admin/article", requestBody)
        .then((res) => {
          if (res.data.status === 200) {
            this.dataTable = res.data.data.article.list;
            this.pageCount = res.data.data.article.pagination.last_page;
            this.itemCount = res.data.data.article.pagination.per_page;
            this.itemTotal = res.data.data.article.pagination.total;
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },

    updateStatus(p) {
      const requestBody = {
        article_hash: p,
      };

      axios
        .post("/v1/admin/article/update-status", requestBody)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.success(res.data.message);
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },

    submit() {
      const requestBody = {
        article_hash: this.selectedItem.article_hash,
      };

      axios
        .post("/v1/admin/article/delete", requestBody)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.success(res.data.message);
            this.initialize(this.page);
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0][0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },

    checkboxShowWeb(item) {
      const requestBody = {
        article_hash: item.article_hash,
      };

      axios
        .post("/v1/admin/article/update-show-web", requestBody)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.success(res.data.message);
            // this.initialize(this.page);
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0][0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.action-container {
  background: #f2f2f2;
}

// .action-container:hover {
//   background: #2B7DEC;
// }

// .action-container:hover > .action-icon {
//   color: #f2f2f2;
// }

.action-icon {
  font-size: 16px;
  color: #2b7dec;
}

tbody > tr:hover {
  background-color: transparent !important;
}

.action-style {
  width: 36px;
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
}
</style>
